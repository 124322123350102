<template>
  <div class="circular-banner-card" :style="{ 'background-image': $h.getStyleImage(data?.Image, 'BANNER', null, true) }" @click="onViewBanner"></div>
</template>

<script>
import { defineComponent, inject } from "vue";
import { helpers } from "@/utils/helpers.js";

import { f7 } from "framework7-vue";

export default defineComponent({
  name: "CircularBanner",
  components: {},
  props: {
    data: Object,
    f7router: Object,
    f7route: Object,
  },
  setup(props) {
    const onViewBanner = () => {
      let bannerLink = props?.data?.Link;
      if (!bannerLink) return;

      if (bannerLink.indexOf("http") > -1) {
        return helpers.deepLinking(props.f7router, bannerLink);
      }

      if (bannerLink.indexOf("/") > -1) {
        return props.f7router.navigate(bannerLink);
      }

      if (props?.f7router?.routes?.length > 0) {
        let isFound = false;
        for (let item of props?.f7router?.routes) {
          if (item.name == bannerLink) {
            isFound = true;
            break;
          }
        }

        if (isFound) return props.f7router.navigate({ name: bannerLink });
        props.f7router.navigate({ name: "productViewPage", params: { code: bannerLink } });
      }
    };

    return { onViewBanner };
  },
});
</script>

<style scope>
.circular-banner-card {
  border: 1px solid #e2e2e2;
  width: 87px;
  height: 87px;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 10px;
  cursor: pointer;
}
</style>
